.modal-content {
  justify-content: center;
  padding: 44px !important;
  width: 70% !important;
  min-height: auto !important;
  transform: translate(20%, 2%);
}

.modules-modal {
  width: 100% !important;
}

@media only screen and (max-width: 1258px) {
  .modal-content {
    width: 90% !important;
    padding: 24px !important;
    transform: translate(5%, 2%);
  }
  .osp_modal-modules {
    width: 130px !important;
    height: 150px !important;
  }
}

@media only screen and (max-width: 767px) {
  .modal-content {
    width: 100% !important;
    padding: 24px !important;
    transform: translate(-2%, 2%) !important;
  }
  .modules-modal {
    width: auto !important;
  }
  .osp_modal-modules {
    width: 125px !important;
    height: 165px !important;
  }
}
