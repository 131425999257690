@use '../abstracts/functions' as *;

.mobile_menu-border {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile_menu-border {
    position: absolute;
    top: 80px;
    width: 100%;
    height: 1px;
    background-color: get-color('gray--05');
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .burger_menu {
    display: none;
  }
}
