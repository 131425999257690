.details_img {
  max-width: 907px !important;
  max-height: 557px !important;
}

.review_img {
  max-width: 907px !important;
  max-height: 425px !important;
  position: relative;
  top: -100px;
  margin-bottom: -150px;
}

@media only screen and (max-width: 800px) {
  .review_img {
    width: 400px;
    height: auto;
  }
}
