@use './colors' as *;
@use './functions' as *;

@mixin add-font-face($fontFamily, $basePath, $formats, $defList) {
  @each $def in $defList {
    $src: ();
    @each $format in $formats {
      $ext: $format;

      @if ($format == 'truetype') {
        $ext: 'ttf';
      }

      $url: url('#{$basePath}/#{map_get($def, "file")}.#{$ext}') format(quote($format));
      $src: append($src, $url, comma);
    }

    @font-face {
      font-family: quote($fontFamily);
      font-weight: map_get($def, 'weight');
      font-style: map_get($def, 'style');
      src: $src;
    }
  }
}

@mixin input {
  color: get-color('gray--02') !important;
  border-radius: 8px;
  font-weight: 300;
  height: 56px;
  width: 100%;
  &:focus,
  .dropdown-toggle:focus {
    border: 1px solid get-color('primary') !important;
    box-shadow: 0px 0px 0px 4px get-color('validation') !important;
  }
  & + label {
    font-weight: 200 !important;
    color: get-color('gray--03');
  }
  &:focus + label {
    font-weight: 300 !important;
  }
  &:not(:placeholder-shown):not(.login_email-invalid):valid {
    border: 1px solid get-color('primary') !important;
    box-shadow: 0px 0px 0px 4px get-color('validation') !important;
  }
  &:not(:placeholder-shown)[type='email']:invalid {
    border: 1px solid get-color('secondary') !important;
    box-shadow: 0px 0px 0px 4px get-color('error') !important;
  }
}

input:placeholder-shown:invalid {
  border: 1px solid get-color('gray--05') !important;
  box-shadow: none !important;
}

input:not[type='radio']:invalid {
  border: 1px solid get-color('secondary') !important;
  box-shadow: 0px 0px 0px 4px get-color('error') !important;
}

.ams_invalid {
  font-weight: 300;
  border: 1px solid get-color('secondary') !important;
  box-shadow: 0px 0px 0px 4px get-color('error') !important;
}

.ams_valid {
  border: 1px solid get-color('primary') !important;
  box-shadow: 0px 0px 0px 4px get-color('validation') !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: none !important;
    margin-right: initial !important;
    margin-left: initial !important;
  }
}
