$colors: (
  spring-green: #2ca58d,
  tomato: #fa6440,
  sunflower: #feba00,
  white: #ffffff,
  white-: #ffffff,
  spring-green--light: #eaf6f4,
  tomato--light: #fff0ec,
  primary: #2ca58d,
  hover: #2c7d6d,
  secondary: #fa6440,
  accent: #feba00,
  validation: #eaf6f4,
  error: #fff0ec,
  background: #fafafa,
  gray--01: #060d19,
  gray--02: #424750,
  gray--03: #8a8d93,
  gray--04: #adafb3,
  gray--05: #c6c7ca,
  gray--06: #d0d1d3,
  gray--07: #e4e4e6,
  gray--08: #f3f3f4,
  gray--09: #fafafa,
);

@each $name, $hex in $colors {
  .color_text-#{$name} {
    color: $hex !important;
  }
  .color_bg-#{$name} {
    background-color: $hex !important;
  }
  .color_border-#{$name} {
    border: 1px solid $hex !important;
  }
}
