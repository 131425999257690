.dropdown_account-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  flex-basis: auto;
  justify-content: flex-end;
  flex-grow: 1;
  z-index: 1 !important;
  button {
    border-radius: 50% !important;
    text-align: center;
    line-height: 30px;
    width: 42px;
    aspect-ratio: 1;
  }
  button::after {
    display: none !important;
  }
}

#dropdown_caret-none {
  background-color: transparent !important;
  border: none !important;
  // position: relative;
  // top: -7px;
  &::after {
    display: none !important;
  }
}
