@use '../abstracts/functions' as *;

.account_profile-container {
  padding-top: 3em !important;
}

.account_profile-row {
  padding-top: 2em !important;
  padding-bottom: 0.5em !important;
  &::after {
    content: '';
    position: absolute;
    width: 98%;
    height: 1px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--gray5);
  }
}

.account_manage-column {
  padding-top: 2em !important;
  padding-bottom: 0.5em !important;
  &::after {
    content: '';
    position: absolute;
    width: 98%;
    height: 1px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: get-color('gray--07');
  }
}
