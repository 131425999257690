@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;

.form_background {
  margin: 0 0 56px 0;
  background-image: url('/images/formBg-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.form_login {
  width: 438px;
  padding: 44px;
  border-radius: 12px;
  margin-top: 8em;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 530px) {
  .form_login {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.form_input {
  @include input;
  button {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    background-color: #fff !important;
    border: 1px solid get-color('gray--06') !important;
    color: get-color('gray--02') !important;
    font-weight: 300 !important;
  }
  button:hover {
    border: none;
  }
}

.form_inputs {
  // @include input;
  button {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    background-color: #fff !important;
    border: 1px solid get-color('gray--06') !important;
    color: get-color('gray--02') !important;
    font-weight: 300 !important;
  }
  button:hover {
    border: none;
  }
}

.parcel_listing-input {
  width: min-content !important;
  background-color: transparent !important;
  border: none;
  &:focus {
    outline: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0.15rem rgb(44 125 109 / 25%) !important;
  }
}

.parcel_listing-input-dms {
  width: 300px !important;
  background-color: transparent !important;
  border: none;
  &:focus {
    outline: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0.15rem rgb(44 125 109 / 25%) !important;
  }
}

.certified_select {
  background-color: transparent;
  border: none;
  font-weight: 200;
}

.signup_topp-container {
  text-wrap: balance !important;
}
