@use './vendors/bootstrap' as *;
@use './vendors-extensions/bootstrap' as *;
@use './layouts/navigation' as *;
@use './base/general' as *;
@use './abstracts/breakpoints' as *;
@use './base/typography' as *;
@use './abstracts/colors' as *;
@use './abstracts/functions' as *;
@use './abstracts/mixins' as *;
@use './abstracts/variables' as *;
@use './components/dropdown' as *;
@use './components/form' as *;
@use './components/buttons' as *;
@use './components/radio-buttons' as *;
@use './components/checkbox' as *;
@use './components/modal' as *;
@use './components/info' as *;
@use './components/table' as *;
@use './pages/review' as *;
@use './pages/account' as *;
@use './pages/label-sales' as *;
@use './pages/osp' as *;
@use './pages/activities' as *;
@use './pages/help' as *;

* {
  font-family: 'GT Walsheim', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

.pointer {
  cursor: pointer;
}

.progress-steps {
  float: right;
}

.page_router {
  margin-left: 300px !important;
  margin-right: 100px !important;
}

.todos {
  border: 1px solid get-color('gray--08') !important;
}

.img_help {
  height: 117px;
}

.img_container {
  display: flex;
  justify-content: center;
}

.img_overview {
  width: 50vw;
}

.logo_container {
  position: relative;
  top: 12px;
  left: 120px;
  background-color: #fff;
}
.dashboard-sidebar {
  position: relative;
  top: 0;
  z-index: 0;
  padding-top: 68px !important;
  border-right: solid 2px get-color('gray--07') !important;
}

.logo-primary {
  position: relative;
  z-index: 1;
}

.module_cards {
  background-color: #fff !important;
  border: solid 1px get-color('gray--08') !important;
  min-height: 130px;
}
.at-height {
  min-height: 100vh;
  height: auto;
  position: absolute;
  top: 0;
  width: inherit;
  z-index: 0;
}

.other_text-field {
  width: 437px !important;
}

//  hidden on mobile view / individual styling - remember to move to different scss page
@media only screen and (min-width: 769px) {
  .burger_menu {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-sidebar {
    display: none;
  }
  .navbar-brand {
    display: none;
  }
  .page_router {
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: auto;
  }

  .workflow-steps {
    .list-group-item {
      padding: 20px 15px !important;
    }
  }
  .crop-date-box {
    display: table-cell !important;
  }
  .img-review {
    height: 300px;
  }
  .rec-box {
    display: block !important;
  }
  .form_btn-outline--primary {
    margin-bottom: 12px;
  }

  .other_text-field {
    width: 75% !important;
  }

  .at-padding {
    padding-right: 0 !important;
    overflow: hidden !important;
  }
}

// individual styling - remember to move to different scss page
.main-bloc {
  height: 80vh;
  width: 100%;
}

.map-wrapper {
  position: relative;
  height: 80vh;
  width: 100%;
}

.info-box {
  position: fixed;
  padding: 20px;
}

#menuToggle {
  display: block;
  position: relative;
  top: 24px;
  left: 24px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;

  -webkit-touch-callout: none;
}
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  background: get-color('gray--01');
  border-radius: 3px;
  position: relative;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
#menu {
  position: absolute;
  top: -32px !important;
  left: -32px;
  height: 100vh;
  width: 300px;
  list-style-type: none;
  background-color: get-color('gray--07');
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  transform: none;
}
#parcel-table th,
#parcel-table td {
  border: 1px solid #dee2e6;
  padding: 16px;
}

// }Highlight the row when the user hovers over it
#parcel-table tbody tr:hover {
  background-color: #e9ecef;
}

.wrap-text {
  word-break: break-word;
  white-space: normal;
  word-wrap: break-word;
}
// Alternate row colors (zebra-striping)
#parcel-table tbody tr:nth-child(odd) {
  background-color: #f8fafa;
}
.clickable-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
.spinner-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.spinner-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-instruction-background {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 3px;
  max-width: 180px;
  word-wrap: break-word;
}

.parcel_listing-pencil {
  transform: rotateY(180deg);
  display: inline-block;
}

.parcel_listing-pencil-dms {
  transform: rotateY(180deg);
  display: inline-block !important;
  &:hover {
    cursor: pointer;
  }
}

.parcel_listing-pencil-doc {
  transform: rotateY(180deg);
  display: inline-block !important;
  &:hover {
    cursor: pointer;
  }
}
