th,
td {
  vertical-align: middle !important;
}

.contam_equipment-label {
  display: block;
}

.parcel-listing_no-parcel-table {
  display: table-caption !important;
}

.treated_lumber-truncate {
  width: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}

.treated_lumber-truncate-location {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 15px;
}

.treated_lumber-table {
  table-layout: fixed !important;
  td:last-child {
    width: 10px !important;
    text-align: right;
  }
}

.treated_lumber-contact-title {
  white-space: nowrap;
}

.sort-direction {
  transform: rotate(180deg);
}

.dms-para-sizing {
  width: 75% !important;
}

@media only screen and (max-width: 768px) {
  .dms-para-sizing {
    width: 100% !important;
  }
}
