@use '../abstracts/mixins' as *;
@use '../abstracts/functions' as *;
@use '../abstracts/colors' as *;

@include add-font-face(
  'GT Walsheim',
  'https://cdn.quickorganics.com/fonts/GTWalsheim',
  ('woff2'),
  (
    create-font-map('GT-Walsheim-Ultra-Light-Trial', 100),
    create-font-map('GT-Walsheim-Light-Trial', 200),
    create-font-map('GT-Walsheim-Regular-Trial', 300),
    create-font-map('GT-Walsheim-Medium-Trial', 400),
    create-font-map('GT-Walsheim-Bold-Trial', 500)
  )
);

$titles: (80 70 -0.015), (70 70 -0.0125), (60 60 -0.01), (50 50 -0.005), (40 40 -0.0025), (30 35 0.0015), (24 30 0);
$paragraphs: (40 40 0), (30 35 0.0015), (24 30 0.001), (20 30 0), (17 24 -0.0005), (14 20 -0.0007), (12 16 -0.001);
$i: 0;
$j: 0;

@each $fs, $lh, $ls in $titles {
  $i: $i + 1;
  .title-#{$i} {
    font-size: #{$fs}px !important;
    line-height: #{$lh}px !important;
    letter-spacing: #{$ls}rem !important;
  }
}

@each $fs, $lh, $ls in $paragraphs {
  $j: $j + 1;
  .para-#{$j} {
    font-size: #{$fs}px !important;
    line-height: #{$lh}px !important;
    letter-spacing: #{$ls}rem !important;
  }
}

$weights: (900 800 700 600 500 400 300 200 100);

@each $weight in $weights {
  //Subtracted weight by 100, Figma mockup states to use 400 but when used the font is bolder than the mockup. Temporarily reduced font weight.
  $new-weight: $weight;
  @if $weight > 100 {
    $new-weight: $weight - 100;
  } @else {
    $new-weight: $weight;
  }
  .fw-#{$weight} {
    font-weight: #{$new-weight} !important;
  }
}

.link_hover {
  transition: color 300ms ease 0s !important;
  &:hover {
    color: get-color('hover') !important;
  }
}

.link_underline {
  text-decoration: underline !important;
}

.link_underline-none {
  text-decoration: none !important;
}

.text-italic {
  font-style: italic;
}
