@use '../abstracts/functions' as *;

.osp_modal-modules {
  border: 1px solid get-color('gray--06');
  border-radius: 12px;
  height: 156px;
  width: 171px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.add_input-label {
  margin-bottom: -12px !important;
}
