// used to override / re-declare any bootstrap default css
@use '../abstracts/functions' as *;

@use '../abstracts/colors' as *;

a {
  text-decoration: none !important;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: get-color('gray--03') !important;
  --bs-dropdown-link-active-color: get-color('gray--01') !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.h-100 {
  min-height: 100vh !important;
  height: 100% !important;
}

.form-check {
  padding-left: 0 !important;
  margin-bottom: 16px !important;
}

.dropdown-menu.show {
  width: 100%;
}

.modal-footer {
  justify-content: center;
  border-top: none;
}

.nav-link {
  color: get-color('gray--03');
  font-weight: 200;
  background-color: transparent !important;
  border: none !important;
  &.active {
    color: get-color('primary') !important;
    border-bottom: 1px solid get-color('primary') !important;
  }
  &:hover {
    color: get-color('hover');
  }
}

.progress-bar {
  background-color: get-color('primary') !important;
}

.tooltip-arrow {
  display: none !important;
}

.tooltip [class$='inner'] {
  max-width: 300px !important;
  text-align: left !important;
  border: 1px solid get-color('gray--06') !important;
  background-color: get-color('white-') !important;
  color: get-color('gray--02');
  font-size: 14px !important;
  font-weight: 200 !important;
  line-height: 20px !important;
  letter-spacing: -0.07% !important;
  padding: 8px !important;
  width: 284px !important;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05) !important;
}

.list-group-item {
  border: none !important;
}

.list-group-item.active {
  background-color: transparent !important;
  color: get-color('primary') !important;
  border: none !important;
}

.card,
.card-body {
  border-width: 1px !important;
  border-color: get-color('gray--08') !important;
}

.card {
  height: 100% !important;
}

label {
  display: inline !important;
}

.form-check-input:checked {
  background-color: transparent !important;
  border: transparent !important;
}

.btn-group {
  height: 44px !important;
}

.fixed-top {
  margin-right: 100px !important;
}

// .row > * {
//   padding-right: 0 !important;
// }

@media only screen and (max-width: 768px) {
  .fixed-top {
    margin-right: 0 !important;
  }
  .row {
    margin-right: 0 !important;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 10;
}

.table-responsive {
  overflow-x: visible !important;
}

.dropdown-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.page-link {
  background-color: transparent !important;
  border: none !important;
  color: get-color('primary') !important;
  span {
    font-size: 30px !important;
    font-weight: 100 !important;
    line-height: 24px;
    position: relative;
    top: -2px;
    margin-left: 24.5px;
    margin-right: 24.5px;
  }
}

.btn-group {
  height: auto !important;
}

.btn-group {
  height: auto !important;
}

.list-group {
  --bs-list-group-bg: transparent !important;
}
