@use '../abstracts/functions' as *;

input[type='radio'] {
  -webkit-appearance: inherit;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  outline: none;
  margin-top: -5px;
  margin-right: 16px;
  vertical-align: middle !important;
  box-shadow: 0 0 0 2px get-color('primary');
  &:checked {
    box-shadow: 0 0 0 2px get-color('primary');
  }
  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 8px auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: get-color('primary');
    border: none;
  }
}

.at_inputs-reasons label {
  position: relative;
  top: -6px;
}

.btn-check:checked + .form_btn-checked {
  background-color: get-color('validation') !important;
  border: 1px solid get-color('primary') !important;
}
