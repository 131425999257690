.info_container {
  display: flex;
  align-items: center;
}

.info_icon {
  height: 24px;
  width: 24px;
  margin-left: 8px;
}
