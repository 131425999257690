$spacing: (1 2 3 4 5 8 10 12 15 16 20 24 25 30 32 35 36 40 44 45 48 50 56 64 65 68 96 100 200 300);
$sides: (top, bottom, left, right);

@each $space in $spacing {
  @each $side in $sides {
    .margin_#{$side}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .margin-#{$space} {
      margin: #{$space}px !important;
    }
    .padding_#{$side}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .padding-#{$space} {
      padding: #{$space}px !important;
    }
  }
}

$widths: (50 100 200 250 300 400 450 500);
$heights: (1 50 100 200 250 300 400 450 500);

@each $width in $widths {
  .width-#{$width} {
    width: #{$width}px !important;
  }
  .width-#{$width}--max {
    max-width: #{$width}px !important;
  }
  .width-#{$width}--min {
    min-width: #{$width}px !important;
  }
  .width-auto {
    width: 100%;
  }
}

@each $height in $heights {
  .height-#{$height} {
    height: #{$height}px !important;
  }
  .height-#{$height}--max {
    max-height: #{$height}px !important;
  }
  .height-#{$height}--min {
    min-height: #{$height}px !important;
  }
}

$radius: (2 4 5 6 8 10 12 14 15);

@each $num in $radius {
  .br-#{$num} {
    border-radius: #{$num}px !important;
  }
}
