@use '../abstracts/functions' as *;

input[type='checkbox'] {
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 8px !important;
  border-color: get-color('primary') !important;
  background-color: transparent !important;
  border-width: 2px !important;
  margin-right: 16px !important;
  float: left;
  j &:focus {
    box-shadow: 0 0 0 0.25rem rgb(44 125 109 / 25%) !important;
  }
  &:checked {
    background-color: get-color('primary') !important;
  }
  & + label {
    display: block !important;
    margin-left: 42px !important;
    margin-top: -17px;
  }
}

input[type='checkbox'][class='form_input-radio'] + label {
  display: auto !important;
  margin-left: 0px !important;
  margin-top: 0rem !important;
}

input[type='checkbox'][class='form-check-input'] + label {
  display: auto !important;
  margin-left: 0px !important;
  margin-top: 0rem !important;
}

.single-radio-option {
  display: auto !important;
  margin-left: -42px !important;
  margin-top: 30px !important;
}

input[type='checkbox'][class='form_input-radio'] {
  width: 24px !important;
  height: 24px !important;
  appearance: none !important;
  border: 3px solid get-color('primary') !important;
  border-radius: 50% !important;
  position: relative;
  display: grid !important;
  place-items: center !important;
}

input[type='checkbox'][class='form_input-radio']::after {
  content: '' !important;
  position: absolute !important;
  width: 30% !important;
  height: 30% !important;
  border-radius: 50% !important;
  background-color: get-color('primary') !important;
  transition: opacity 0.2s ease-in-out !important;
  opacity: 0 !important;
}

input[type='checkbox'][class='form_input-radio']:checked {
  background-color: transparent !important;
}

input[type='checkbox'][class='form_input-radio']:checked::after {
  opacity: 1 !important;
}

.form_checkbox-container {
  display: flex !important;
  align-items: center !important;
  label {
    position: relative;
    top: 2.5px;
  }
}
