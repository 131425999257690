@use '../abstracts/functions' as *;
@use '../abstracts/colors' as *;

.form_btn {
  background-color: get-color('primary') !important;
  border-radius: 8px !important;
  border-color: get-color('primary') !important;
  padding: 12px 44px;
  color: get-color('white-');
  min-height: 48px !important;
  font-weight: 300 !important;
  border: get-color('primary');
  &:hover {
    background-color: get-color('hover') !important;
    color: get-color('white-') !important;
  }
  &:focus {
    box-shadow: 0px 0px 0px 4px get-color('validation') !important;
  }
}

.form_btn-outline--primary {
  background-color: transparent !important;
  border-radius: 8px !important;
  border: 1px solid get-color('primary') !important;
  padding: 12px 32px !important;
  font-weight: 300;
  min-height: 50px !important;
  min-width: 150px !important;
  color: get-color('primary') !important;
  &:hover {
    background-color: get-color('primary') !important;
    color: #fff !important;
  }
}

.form_btn-outline--delete {
  background-color: get-color('secondary') !important;
  border-radius: 8px !important;
  border: 1px solid get-color('secondary') !important;
  padding: 12px 32px !important;
  font-weight: 300;
  min-height: 50px !important;
  min-width: 150px !important;
  color: get-color('-white') !important;
  &:hover {
    opacity: 0.5;
    border: none !important;
    color: #fff !important;
  }
}

.form_btn-activity {
  background-color: transparent !important;
  border-radius: 8px !important;
  border: none !important;
  img:hover {
    opacity: 0.8;
  }
}

.form_btn-outline--gray {
  background-color: transparent !important;
  border-radius: 8px !important;
  border: 1px solid get-color('gray--04') !important;
  padding: 12px 32px;
  font-weight: 300;
  color: get-color('primary');
  min-width: 109.5px !important;
  max-height: 48px !important;
  text-align: center;
  &:hover {
    background-color: get-color('primary') !important;
    color: #fff !important;
    border: none !important;
    cursor: pointer !important;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px get-color('gray--04') !important;
  }
  &:checked {
    background-color: get-color('validation') !important;
  }
  &.parcel-listing {
    padding: 8px 12px !important;
  }
}

.at_form_btn-outline--gray {
  background-color: #fff !important;
  border-radius: 8px !important;
  border: 1px solid get-color('gray--04') !important;
  padding: 12px 32px;
  font-weight: 300;
  color: get-color('primary');
  min-width: 109.5px !important;
  max-height: 48px !important;
  text-align: center;
  &:hover {
    background-color: get-color('primary') !important;
    color: #fff !important;
    border: none !important;
    cursor: pointer !important;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px get-color('gray--04') !important;
  }
  &:checked {
    background-color: get-color('validation') !important;
  }
}

.at_btn-footer {
  justify-content: space-between !important;
}

.at_btn-continue {
  justify-content: end !important;
}

.form_btn-no-outline {
  background-color: transparent !important;
  color: get-color('gray--01');
  font-weight: 200;
  border: none !important;
  display: flex;
  align-items: center;
  word-spacing: 8px;
  text-decoration: none !important;
  width: 150px;
  &:hover,
  &:active,
  &:focus {
    color: get-color('gray--01');
    border: none !important;
    box-shadow: none !important;
  }
  &.parcel-listing {
    display: flex;
    float: right;
    width: auto !important;
    word-break: keep-all !important;
  }
}

.form_btn-no-outline--delete {
  background-color: transparent !important;
  color: get-color('secondary');
  font-weight: 200;
  border: none !important;
  display: flex;
  align-items: right;
  vertical-align: middle !important;
  word-spacing: 8px;
  text-decoration: none !important;
  margin-left: 10px;
  height: 36px !important;
  &:hover {
    color: get-color('gray--02') !important;
  }
}

.form_btn-no-outline--login {
  background-color: transparent !important;
  color: get-color('primary');
  font-weight: 200;
  border: none !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  width: 150px;
  &:hover,
  &:active,
  &:focus {
    color: get-color('gray--02') !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.form_btn-rounded {
  border-radius: 40px !important;
}

.form_btn-no-padding {
  padding: 0 !important;
}

.form_btn-link {
  background-color: transparent !important;
  color: get-color('gray--01');
  font-weight: 200;
  border: none !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  &:hover,
  &:active,
  &:focus {
    color: get-color('gray--02') !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.parcel_listing-pencil:hover {
  cursor: pointer;
}

.parcel_listing-check:hover {
  cursor: pointer;
}

.all-image-thumbnail_container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 24px;
}

.image-thumbnail_container {
  position: relative;
  display: inline-block;
}

.image-thumbnail {
  flex-grow: 1;
  width: 106px;
  height: 106px;
}

.image-thumbnail:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.image-thumbnail-close {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: get-color('gray--02');
  border-radius: 50%;
  border: 1px solid get-color('white-');
  color: get-color('white-');
  height: 20px;
  width: 20px;
  text-align: center;
  &:hover {
    opacity: 0.5;
  }
}

.image-thumbnail-close span {
  position: relative;
  top: -4.5px;
  right: 1.5px;
}
